import React, { useEffect, useRef, useState } from 'react'
import mic from '../img/mic.svg'
import note from '../img/note.svg'
import penSelected from '../img/pen_selected.svg'
import trash from '../img/trash.svg'
import micIcon from '../img/mic-icon.svg'

import ImageViewer from 'react-simple-image-viewer';
import { PromptModal } from './Modals'
import { t } from 'i18next'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AudioRecorder from './AudioRecorder'
import WaveformPlayer from './WaveformPlayer'
import { TextNotePrompt } from './UserPrompt'
import { db } from '../db';

const NoteTaker = ({notes,setNotes, setShowingPrompt}) => {
    // hooks 
    const photoInput = useRef(null)
    const intervalRef = useRef(null);

    // local state
    const [reloadNotes, setReloadNotes] = useState(false)
    const [isPressingMic, setIsPressingMic] = useState(false)
    const [voiceNotePercentage, setVoiceNotePercentage] = useState(0)
    const [currentImage, setCurrentImage] = useState(0);
    
    const [editNote, setEditNote] = useState(null)
    const [audioURL, setAudioURL] = useState('');

    const [photoNotes, setPhotoNotes] = useState([])

    // modal states 
    const [showAddTextNote, setShowAddTextNote] = useState(false)
    const [showEditTextNote, setShowEditTextNote] = useState(false)
    const [showAddVoiceNote, setShowAddVoiceNote] = useState(false)

    const [isViewerOpen, setIsViewerOpen] = useState(false)
    const [isRecording, setIsRecording] = useState(false)

    const [voicePhoto, setVoicePhoto] = useState(null)
    const [photoEdit, setPhotoEdit] = useState(null)

    const notesString = JSON.stringify(notes)

    const handleAddNote = (type ) =>{
        switch (type) {
            case 'voice':
                setShowAddVoiceNote(true)
                break;
            case 'text':
                setShowAddTextNote(true)
                break;
            default: 
                break;
        }
    }

    const handleAddPhoto = () => {
        photoInput?.current?.click()
    }

    const handlePhotoUpload = async (e) => {
        if(!e.target.files) return 
        const file = await toBase64(e?.target?.files[0])
        setVoicePhoto(file)
      }  

    const clearVoicePhoto = () => {
        photoInput.current.value = null
        setVoicePhoto(null)
    }

    const handleTextNoteResponse = async (text,photo) => {
        const timestamp = new Date().getTime()
        const prevnotes = notes ?? []
        prevnotes.push({type: 'text', value : text, timestamp })

        if(photo){
            const randomId = 'photo_note_' + Math.floor(100000 + Math.random() * 900000)
            db.images.add({key: randomId, value : photo, timestamp})
            prevnotes.push({type: 'photo', value : randomId, timestamp })
        }
        setNotes(prevnotes)
        setShowAddTextNote(false)
    }


    // use timestamp to look for note 
    const handleEditTextNoteResponse = (text, photoInput) => {
        if(!editNote?.note?.timestamp) return
        const noteTimestamp = editNote?.note?.timestamp

        // look for text note and modify with new values 
        let prevnotes = notes.map( note => {
            if(note.timestamp === noteTimestamp && note.type === 'text'){
                return {type : 'text', value : text, timestamp: noteTimestamp}
            } else{
                return note
            }
        })

        // look for prev photo existing with same and timestamp to filter out 
        const prevPhotoIndex = prevnotes.findIndex(note => note.timestamp === noteTimestamp && note.type === 'photo')

        if(prevPhotoIndex > 0){
            const prevPhoto = prevnotes[prevPhotoIndex]

            // delete from prevnotes array 
            prevnotes.splice(prevPhotoIndex,1)

            // delete from local db
            db.images.delete(prevPhoto.value)
        }

        // if there is a new photo add it 
        if(photoInput){
            // add to local db
            const randomId = 'photo_note_' + Math.floor(100000 + Math.random() * 900000)
            db.images.add({key: randomId, value : photoInput, timestamp : noteTimestamp})

            // add to prevnotes array 
            prevnotes.push({type: 'photo', value : randomId, timestamp : noteTimestamp})
        }
        
        // save photo note state 
        const newPhotoNotesArr = prevnotes.filter(note => note.type === 'photo')
        setPhotoNotes(newPhotoNotesArr)  
        setNotes(prevnotes)
        setEditNote(null)
        setPhotoEdit(null)
        setShowEditTextNote(false)
    }

    const handleRecordVoiceNoteResponse = async(agree) => {
        if(agree){
            fetch(audioURL)
                .then(response => response.blob())
                .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = async () => {
                    const timestamp = new Date().getTime()
                    const base64String = reader?.result?.split(',')[1];
                    const prevnotes = notes ?? []
                    prevnotes.push({type: 'voice', value : base64String, timestamp, localUrl : audioURL})
                    if(voicePhoto){
                        const prevnotes = notes ?? []
                        const photo = voicePhoto

                        const randomId = 'photo_note_' + Math.floor(100000 + Math.random() * 900000)
                        db.images.add({key: randomId, value : photo, timestamp})
                        prevnotes.push({type: 'photo', value : randomId, timestamp})

                        setNotes(prevnotes)   
                        setVoicePhoto(null)
                        reloadNoteTaker()
                    }
                    setNotes(prevnotes)
                    reloadNoteTaker()
                    setAudioURL('')
                };
                })
                .catch(error => console.error(error));

        } else{
            setAudioURL('')
            setVoicePhoto(null)
        }

        setShowAddVoiceNote(false)
        setVoiceNotePercentage(0)
    }

    const clearAudio = () => {
        setVoiceNotePercentage(0)
        setAudioURL('')
    }

    const handleEdit = (noteToEdit, photoToEdit) => {
        const note = notes.find( (note) => note.timestamp === noteToEdit.timestamp )
        const noteIndex = notes.findIndex( note => note.timestamp === noteToEdit.timestamp)
        if(!note) return 
        if(photoToEdit){
            setPhotoEdit(photoToEdit)
        }
        setEditNote({note,noteIndex})
        setShowEditTextNote(true)
    }

    const openImageViewer = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

   
    const reloadNoteTaker = () => {
        setReloadNotes(true)
        setTimeout(() => {
            setReloadNotes(false)
        }, 5)
    }

    const startCounter = () => {
        setIsRecording(true)
        // Start Recording 
        if (intervalRef.current) return;
        
        intervalRef.current = setInterval(() => {
          setVoiceNotePercentage((prevPercentage) => {
           if(prevPercentage > 100){
            setIsPressingMic(false)
            stopCounter()
           }
            return prevPercentage + 1
          });
        }, 300)
      };
    
      const stopCounter = () => {
        // End recording 
        setIsRecording(false)
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };


    useEffect(() => {
        return () => stopCounter(); // when App is unmounted we should stop counter
    }, []);

    // set showing prompt if any of these prompts are showing
    useEffect(() => {
        if(showAddTextNote || showEditTextNote || showAddVoiceNote || isViewerOpen){
            setShowingPrompt(true)
        } else{
            setShowingPrompt(false)
        }
    },[showAddTextNote, showEditTextNote, showAddVoiceNote, isViewerOpen, setShowingPrompt])

    const handleDeleteNote = (noteToDelete, photoToDelete) => {
        // delete note and photo note if that is included 
        const prevnotes = notes.filter( note => {
            // include notes that do not have matching timestamp and do not have matching keys 
            return note.timestamp !== noteToDelete.timestamp
        })

        // delete image from local db 
        if(photoToDelete){
            db.images.delete(photoToDelete.key)
        }

        // save note state 
        setNotes(prevnotes)

        // save photo note state 
        const newPhotoNotes = prevnotes.filter(note => note.type === 'photo')
        setPhotoNotes(newPhotoNotes)        
    }

    // check if there changes to the notes to set the photo notes state
    useEffect(() => {
        const getPhotoNotes = async () => {
            const photoNotes = notes.filter(note => note.type === 'photo')
            if(photoNotes?.length > 0){
                const newPhotoNotes = []
                for (const photoNote of photoNotes){
                    const photo = await db.images.get({key : photoNote.value})
                    if(photo){
                        newPhotoNotes.push({key: photoNote.value, value: photo.value, timestamp: photoNote.timestamp})
                    }
                }
                setPhotoNotes(newPhotoNotes)
            }
        }
        if(notes){
            getPhotoNotes()
        }
    }, [notesString, notes])


  return (
    <div className='px-3'>
        <div className={`row pb-4 ${notes?.length > 0 ? 'border-bottom' : ''}`}>
            <div className='col-7 align-self-center'>
                <span className='fw-bold fs-3'>{t('notes')}</span>
            </div>
            <div className='col-5 d-flex justify-content-between align-items-center ps-0'>
                <img src={mic} className="" alt="" onClick={() => handleAddNote('voice')}/>
                <img src={note} alt="" onClick={() => handleAddNote('text')}/>
            </div>
        </div>

        {/* Photo Viewer */}
        {isViewerOpen && photoNotes && photoNotes.length > 0 && (
            <div className='image-viewer-container inside-full-page'>
                <ImageViewer
                src={photoNotes.map(photoNote => photoNote.value)}
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
            {/* <img className='delete-btn' src={trash} alt="" onClick={deleteNotePhoto} /> */}
            </div>
        )}

        {/* photo input */}
        <input className='d-none' ref={photoInput} type="file" name="myImage" accept="image/*" onChange={handlePhotoUpload}/>

        {/* Notes feed */}
        <div className={`${(showAddTextNote || showEditTextNote || showAddVoiceNote) ? 'd-none' : ''}`}>

            {/* Regular Text note */}
            {notes && notes.length > 0 && notes.filter(note => note.type === 'text').map((note, index) => {
                // look for linked photo 
                const photo = photoNotes.find(photo => photo.timestamp === note.timestamp)
                return <div key={index} className="border-bottom pb-3">
                        <RegularTextNote index={index} text={note.value} onDelete={() => {handleDeleteNote(note,photo)}} onEdit={() => handleEdit(note,photo)}/>
                        {photo && <img className='img-fluid rounded' style={{maxHeight: '175px'}} src={photo.value} alt="" onClick={ () => openImageViewer(index)} />}
                    </div>          
            })}

            {/* Voice note */}
            {notes && notes.length > 0 && notes.filter(note => note.type === 'voice' && note.localUrl).map((note, index) => {
                const photo = photoNotes.find(photo => photo.timestamp === note.timestamp)
                return <div key={index} className={`border-bottom`}>
                        <WaveformPlayer  audioFileName={note.localUrl} clearAudio={() => {handleDeleteNote(note,photo)}} isLocalLoad={true} />
                        {photo && <img className='img-fluid rounded' style={{maxHeight: '175px'}} src={photo.value} alt="" onClick={ () => openImageViewer(index)} />}
                </div>
            })}

        </div>


        {showAddTextNote && <TextNotePrompt title={t('type_note')} onCancel={() => setShowAddTextNote(false)}
        onDone={handleTextNoteResponse} insideFullPage={true}/>}


        {showAddVoiceNote && <PromptModal title={t('record_voice_note')}
                body={<>
                    <p>{t('record_voice_note_text')}</p>
                    <div className={`d-flex flex-column align-items-center mic-circle-container`}>
                        <CircularProgressbar value={voiceNotePercentage} 
                            strokeWidth={6}
                            styles={buildStyles({
                                trailColor: 'transparent',
                                pathColor: `#0d1c4b`
                            })}/>
         
                        <button className={`mic-btn ${audioURL !== '' ? 'bg-secondary opacity-50 pe-none' : ''}`}
                            onTouchStart={() => {startCounter(); setAudioURL(''); setIsPressingMic(true); }}
                            onTouchEnd={() => {stopCounter(); setIsPressingMic(false)}}
                            >
                            <img src={micIcon} alt="micphrone-button"/>
                        </button>

                        <span className='my-2 d-flex align-items-center' >
                            {isPressingMic && <div className="recording-bubble me-2"></div>}
                            {isPressingMic ? `Recording 00:${Math.floor(voiceNotePercentage * 0.30) < 10 ? '0' :''}${Math.floor(voiceNotePercentage * 0.30)}` : 
                                voiceNotePercentage > 0 ? `00:${Math.floor(voiceNotePercentage * 0.30) < 10 ? '0' :''}${Math.floor(voiceNotePercentage * 0.30)}` : 
                                '00:00 - 00:30'
                        }</span>
                        <AudioRecorder isRecording={isRecording} audioURL={audioURL} setAudioURL={setAudioURL} clearAudio={clearAudio}/>
                        {/* display image */}
                        {voicePhoto && <div className='d-flex mt-3 mx-3'>
                                <div className='position-relative' style={{maxWidth: '125px', maxHeight: '180px'}}>
                                    <img className='img-fluid rounded shadow-sm' src={voicePhoto} alt="" style={{maxHeight: '200px'}} />
                                    <button className='d-block position-absolute top-0 end-0 bg-transparent border-0 pt-1' onClick={clearVoicePhoto}>
                                    <img className='delete-btn' src={trash} alt=""/>
                                    </button>
                                </div>
                        </div>}
                    </div>
                </>}
                choices={[
                    { text: 'cancel', btnClass: 'ubi-button secondary-btn', onClick: () => handleRecordVoiceNoteResponse(false) },
                    { text: 'done', btnClass: `ubi-button ${audioURL === '' ? 'disabled-btn' : '' }`, onClick: () => handleRecordVoiceNoteResponse(true) },
                    { text: 'add_photo', btnClass: `ubi-button ${voicePhoto ? 'disabled-btn' : ''}`, onClick: () => handleAddPhoto(true) }
            ]} />}

        {showEditTextNote && <TextNotePrompt title={t('edit_note')}
            defaultValue={editNote?.note?.value}
            onCancel={() => setShowEditTextNote(false)} 
            onDone={handleEditTextNoteResponse}
            insideFullPage={true}
            inputPhoto={photoEdit}
        />}

    </div>
  )
}

export default NoteTaker

const RegularTextNote = ({text = t('type_note'), onEdit, onDelete, index}) => {

    return <div className={`row py-3`}>
                <div className="col-7 pe-4">
                    <span className='text-break'>{text}</span>
                </div>
                <div className="col-5 d-flex justify-content-between align-items-center ps-0">
                    <img src={penSelected} alt="" onClick={onEdit} />
                    <img src={trash} alt="" onClick={onDelete} />
                </div>
            </div>
}


const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });