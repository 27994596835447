import { combineReducers, applyMiddleware, legacy_createStore} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import jwt_decode from "jwt-decode";


// import reducer
import { loginReducer, appStatusReducer, customerListReducer,
         justLoggedInReducer, submitInstallationReducer, 
         installationListReducer, customerSelectedReducer,
         installationUnpairReducer, checkConnectionReducer, nodeReducer,
         installationTypeReducer, globalLoaderReducer, changePasswordReducer, 
         resetPasswordReducer, mnsListReducer, installationReportReducer, offlineInstallationListReducer } from './redux-reducers'

const reducer = combineReducers({
    globalLoader : globalLoaderReducer,
    userLogin : loginReducer,
    appStatus: appStatusReducer,
    customerList : customerListReducer,
    justLoggedIn : justLoggedInReducer,
    checkConnection : checkConnectionReducer,
    submitInstallation : submitInstallationReducer,
    installationList : installationListReducer,
    installationReport : installationReportReducer,
    installationUnpair : installationUnpairReducer,
    customerSelected : customerSelectedReducer,
    installationTypeSelected : installationTypeReducer,
    changePassword : changePasswordReducer,
    resetPassword : resetPasswordReducer,
    nodeInfo : nodeReducer,
    mnsList : mnsListReducer,
    offlineInstallationList: offlineInstallationListReducer
})

// check if token is present & valid
const access_token = localStorage.getItem('access_token')
let userLoginState = { loading : false }

if(access_token){
    let decoded_token

    try {
        decoded_token = jwt_decode(access_token)
    } catch (error) {}
    
    userLoginState = { loading : false, user : {...decoded_token, access_token}}
}

// check if customer is present on storage
let customer = localStorage.getItem('customer')

// check if install type is present on storage 
let installType = localStorage.getItem('install_type')

const initialState = {
    userLogin: userLoginState,
    customerList : {},
    justLoggedIn : false,
    customerSelected : customer ? JSON.parse(customer) : null,
    installationTypeSelected : installType ? JSON.parse(installType) : null,
    mnsList : [],
    offlineInstallationList : {installations : []}
}


// CREATE THE STORE
const middleware = [thunk]

const store = legacy_createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store


