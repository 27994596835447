import React, { useState, useEffect, useContext } from "react";
import CryptoJS from 'crypto-js'
import { components } from 'react-select';
import arrowDown from '../src/img/arrow-down.svg'
import { db } from "./db";
import imageCompression from 'browser-image-compression'
import UAParser from 'ua-parser-js';

const SECRET_IV = process.env.REACT_APP_SECRET_IV
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY


const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(true)

  useEffect(() => {
    setOnlineStatus(navigator.onLine);
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });
    window.addEventListener("online", () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });
      window.removeEventListener("online", () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};

export const base64ToBlob = async (base64, mimeType) => {
  const byteCharacters = atob(base64);
  const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

export const blobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
});

export const compressImage = async (dataUri) => {
  let image = null
  try {
    const type = dataUri.split(';')[0].split(':')[1]
    const uri = dataUri.split(',')[1]
    const blobPic = await base64ToBlob(uri,type)

    console.log(`originalFile size ${blobPic.size/ 1024 / 1024} MB`);

    const compressedBlobPic = await imageCompression(blobPic, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      });
    
    console.log(`compressedFile size ${compressedBlobPic.size/ 1024 / 1024} MB`); // smaller than maxSizeMB

    image = await blobToBase64(compressedBlobPic)    
  } catch (error) {
    console.log(error);
  }
  return image
}

export const getCurrentLocation = (cb) => {
    let coordinates
    
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
  
    function success(pos) {
      coordinates = pos.coords;  
      cb(coordinates)
    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      cb(err)
    }
  
    navigator.geolocation.getCurrentPosition(success, error, options);
  
  
  }

export const getLongAndLat = () => {


  return new Promise((resolve, reject) =>{
      // function showError(error) {
      //   switch(error.code) {
      //     case error.PERMISSION_DENIED:
      //      alert("User denied the request for Geolocation.")
      //       break;
      //     case error.POSITION_UNAVAILABLE:
      //      alert("Location information is unavailable.")
      //       break;
      //     case error.TIMEOUT:
      //      alert("The request to get user location timed out.")
      //       break;
      //     case error.UNKNOWN_ERROR:
      //      alert("An unknown error occurred.")
      //       break;
      //   }
      // }
      return navigator.geolocation.getCurrentPosition(resolve, reject)
  });
}

export const encryptPass = credentials =>{
  const secret_key = CryptoJS.enc.Hex.parse(SECRET_KEY);
  const secret_iv = CryptoJS.enc.Hex.parse(SECRET_IV);
  const encryptedString = 
  CryptoJS.AES.encrypt(JSON.stringify(credentials),secret_key,
  {
    iv: secret_iv,
    padding: CryptoJS.pad.ZeroPadding,
  }).toString();

  return JSON.stringify(encryptedString.trim());
}

// dropdown indicator for react-select
export const DropdownIndicator = props => {
  return <components.DropdownIndicator {...props}><img src={arrowDown}/></components.DropdownIndicator>
}

export const clearInstallLocalStorage = () => {
  localStorage.removeItem('installState')
  localStorage.removeItem('installConnection')

  // clear index db 
  db.images.clear()
}

export const hasPermissions = async (permission) => {
  let permissionsGranted = null

  if (!navigator.permissions) {
    // Fallback for browsers that do not support the Permissions API
    console.log('Permissions API is not supported. Please manually check camera access.')
    return false
  }

  try {
    const permissionStatus = await navigator.permissions.query({ name: permission })

    if (permissionStatus.state === 'granted') {
        console.log( permission + ' access granted.')
        permissionsGranted = true
    } else if (permissionStatus.state === 'denied') {
        console.log( permission + ' access denied.')
        permissionsGranted = false
    } else {
        console.log( permission + ' access prompt.')
        permissionsGranted = false
    }
  } catch (error) {
      console.error('Error checking camera permissions:', error)
      permissionsGranted = false
  }

  return permissionsGranted
}

export const getBrowserAndOS = () => {
  const parser = new UAParser()
  const result = parser.getResult()
  
  return {
    browser: result.browser.name,
    browserVersion: result.browser.version,
    os: result.os.name,
    osVersion: result.os.version,
    device: result.device.model || 'Desktop'
  }
}
