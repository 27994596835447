import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

import play_btn from '../img/play_btn.svg'
import pause_btn from '../img/pause_btn.svg'
import trash from '../img/trash.svg'
import { getFile } from '../redux/actions';

const WaveformPlayer = ({ audioFileName, clearAudio, isLocalLoad}) => {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    let isCancelled = false;

    if (waveformRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#8996BE',
        progressColor: '#0D1C4B',
        cursorColor: 'navy',
        barWidth: 3,
        barHeight: 2,
        barMinHeight: 1,
        barRadius: 3,
        cursorWidth: 1,
        height: 40,
        barGap: 3
      });

      if(audioFileName){

        if(isLocalLoad){
          wavesurferRef.current.load(audioFileName);
        } else{

          let random = Math.floor(Math.random() * (500 - 50 + 1)) + 50;

          setTimeout(() => {
            getFile(audioFileName)
            .then(blobUrl => {
                if (!isCancelled) {
                    let url = URL.createObjectURL(blobUrl);
                    wavesurferRef.current.load(url);
                }
            })
            .catch(error => console.error(error));
          }, random);
        }

      }

      wavesurferRef.current.on('play', function () {
        setIsPlaying(true)
      });

      wavesurferRef.current.on('pause', function () {
        setIsPlaying(false)
      });

      return () => {
        if (wavesurferRef.current) {
          wavesurferRef.current.destroy();
        }
        isCancelled = true;
      };
    }
  }, [audioFileName]);

  const handlePlay = () => {

    if (wavesurferRef.current) {
        wavesurferRef.current.play();
    }
  }

  const handlePause = () => {
    if (wavesurferRef.current) {
        wavesurferRef.current.pause();
    }
  }

  return (
    <div className='row py-3'>
      <div className='col-7 mt-1 pt-2 pe-4'>
        <div ref={waveformRef} />
      </div>

      <div className='col-5 d-flex justify-content-between align-items-center ps-0 pe-2'>
        {isPlaying ? (
          <img src={pause_btn} alt="" onClick={handlePause} />
        ) : (
          <img src={play_btn} alt="" onClick={handlePlay} />
        )}
        {clearAudio && 
          <button className='ubi-blank-btn' onClick={() => clearAudio()}>
            <img src={trash} alt="" />
          </button>}
      </div>
    </div>
  );
};

export default WaveformPlayer;
