// import dependecies
import * as cn from './redux/constants'
import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate, Outlet, useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { useOnlineStatus, OnlineStatusProvider } from './utils'

// import pages
import Login from './pages/Login'
import Home from './pages/Home'
import Install from './pages/Install'
import ChooseCustomer from './pages/ChooseCustomer'
import ChooseInstallationType from './pages/ChooseInstallationType'
import Account from './pages/Account'
import ForgotPassword from './pages/ForgotPassword'

// import general components
import Header from './components/Header'
import GlobalLoader from './components/GlobalLoader'

// import API redux actions 
import { logout } from './redux/actions'
import UserPrompt from './components/UserPrompt'
import AdminPage from './pages/AdminPage'

function App() {

  // Hooks 
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  // local state 
  const [prompt, setPrompt] = useState(null)

  // Global State 
  const userLogin = useSelector(state => state.userLogin)
  const { user } = userLogin
  const customerSelected = useSelector(state => state.customerSelected)
  const { customers } = useSelector(state => state.customerList)
  const appStatus = useSelector(state => state.appStatus)
  const installationTypeSelected = useSelector(state => state.installationTypeSelected)
  
  // Handle initial acces login page, choose customer page, choose installation type 
  useEffect(() => {

    const workingUrls = ['/login', '/choose-customer','/install-type']

    const handle = () => {  
      // if user switches to forgot password, don't do anything, let user through
      if(location.pathname === '/forgot-password') return null

      // if no user present, go to login 
      if( !userLogin.user) return "/login"

      // if token expired, go to login 
      if( !(userLogin.user.exp > Math.floor(Date.now() / 1000))) return '/login'

      // if no customer selected, go to choose customer 
      if(!customerSelected) return '/choose-customer'

      // verify token online 
      if(workingUrls.includes(location.pathname)){
        fetch(process.env.REACT_APP_SERVER + '/verify-token', {
          method: 'GET',
          headers: { 'Authorization': 'Bearer ' + userLogin.user.access_token ,
          'current-customer-id' : customerSelected.customer_id },
        })
        .then(response => response.json())
        .then(data => {

          // if verifaction fails show prompt
          if(data !== 1){
            setPrompt({
              questionTitle: 'Permission Error', 
              questionText: data.message, 
              agreeText: 'Okay',
              onAgree: () => handlePermissionPrompt(true),
            })
          } 
        })
        .catch(err => {
          console.log(err)
        })
      }

      // if no instalaton selected, go to install type page 
      if(!installationTypeSelected) return '/install-type'

      // all checks passed, if user is on any of these pages, send him to / 
      if(workingUrls.includes(location.pathname)) return '/'
    }

    const page = handle()

    // if page is defined and is not the same as the one user is on, then navigate to it
    if(page && location.pathname !== page){
      navigate(page)
    }

  },[userLogin, customerSelected, installationTypeSelected, location])
  


  // get camera and location permission on first load of the app
  useEffect(() => {
    if(user && location.pathname !== '/' ){
      if (navigator.mediaDevices?.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then(function (stream) {
            const tracks = stream.getTracks();
  
            for (var i = 0; i < tracks.length; i++) {
              var track = tracks[i];
              track.stop();
            }
          })
          .catch(function (err) {
          });
      }
    }
  // eslint-disable-next-line
  }, [userLogin])

  const handlePermissionPrompt = () => {
    setPrompt(null)
    if(customers.length > 1){
      dispatch({
        type : cn.CUSTOMER_SELECTED_CLEAR
      })
    } else{
      dispatch(logout())
    }
  }

  return (
    <>
      <OnlineStatusProvider>
      <GlobalLoader/>
        {appStatus.hasOwnProperty('online') ? (
          <>
            <Header/>
            { prompt && <UserPrompt data={prompt}/>}
            {/* Hide header on the dtm node page */}
            <div id="main-container" className={`${location.pathname === '/map' || location.pathname.startsWith('/node') ? 'header-closed' : '' }`}>
              <Routes>
                  <Route path="/install/*" element={<Install />}/>
                  <Route path="/choose-customer" element={<ChooseCustomer />}/>
                  <Route path="/install-type" element={<ChooseInstallationType />}/>
                  <Route path="/account" element={<Account />}/>
                  <Route path="/*" element={<Home />} exact/>

                  {/* Non-protected routes */}
                  <Route path="/login" element={<Login />} exact/>
                  <Route path="/forgot-password" element={<ForgotPassword />} exact/>

                  <Route path="/ubi-admin-page" element={<AdminPage/>} />
              </Routes>
            </div>
          </>
        ) : <></>}
      </OnlineStatusProvider>
      {/* Show app version on login, choose-customera, install-type and account page */}
      {['/login','/account','/choose-customer', 'install-type'].includes(location.pathname) && (
        <div className='app-version'>{process.env.REACT_APP_VERSION}</div>
      )}
    </>
  )
}

export default App
