// import dependencies 
import React, { useEffect, useState } from 'react'
import * as cn from '../redux/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { DropdownIndicator } from '../utils';
import Select from 'react-select';

// import API redux actions 
import { getCustomers } from '../redux/actions'
import { t } from 'i18next';
import UserPrompt from '../components/UserPrompt';


const ChooseCustomer = () => {

    // Hooks 
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Component State 
    const [customerState, setCustomerState] = useState(null)

    // Global State 
    const { user } = useSelector(state => state.userLogin)
    //const { customerListLoading } = useSelector(state => state.customerList)
    const { customers, loading : customersLoading, error: customersError} = useSelector(state => state.customerList)

    // Set component state to the selected customer from dropdown
    const handleSelectCustomer = cust => {
        const foundCustomer = customers.find(customer => customer.customer_id === cust.customer_id)
        setCustomerState(foundCustomer)
    }

    // Set global state to chosen customer and navigate to install type page 
    const handleChooseCustomerBtn = () => {
        if (!customerState) return alert('Please select a customer')
        dispatch({
            type: cn.CUSTOMER_SELECTED_SET,
            payload: customerState
        })
        //navigate('/install-type')
    }

    // if getCustomers api is not loading and if customer list is empty and usr is logged in, 
    // then call getCustomers api 
    useEffect(() => {
        if (!customersLoading && !customers && user) {
            dispatch(getCustomers())
        }
    // eslint-disable-next-line
    }, [customers])

    const handleRetry = () => {
        dispatch(getCustomers())
    }
    
    return (
        <>
        <div className='full-page container'>
            <div>
                <p>{t('choose_customer_text')}</p>
            </div>
            <div className='my-5 py-4'>

                {/* Loop customers array and populate select element with options as customers  */}
                {customers && customers.length > 0 ? (
                    <>
                    <Select 
                        className={'react-select-container'}
                        components={{DropdownIndicator}}
                        classNamePrefix={'react-select'}
                        isSearchable={true}
                        placeholder={t('choose_customer')}
                        noOptionsMessage={() => t('no_customers')}
                        onChange={handleSelectCustomer}
                        getOptionLabel={option => option.name + ' (' + option.customer_id + ")"}
                        getOptionValue={option => option.customer_id}
                        options={customers.sort((a,b) => a.name.localeCompare(b.name))}/>
                    </>
                ) : (<div className='text-center'><p>{t('no_customers_found')}</p></div>)}
            </div>
            <div className='text-center'>

                {/* Disable button if no customer has been selected */}
                <button onClick={handleChooseCustomerBtn} 
                    className={`btn btn-primary standard-btn fw-bold ${customerState ? '' : 'btn-disabled'}`} >
                        {t('continue')}
                </button>
            </div>
            
        </div>
        {customersError && customersError === 'timeout_get_customers' &&
                <UserPrompt data={
                {
                    questionTitle: t('timeout'), questionText: t('timeout_get_customers'), agreeText: t('retry'),
                    onAgree: () => handleRetry(),
                }
                } />
            }
        </>

    )
}

export default ChooseCustomer