import { useRef, useEffect } from 'react';

import WaveformPlayer from './WaveformPlayer';

const AudioRecorder = ({isRecording, audioURL, setAudioURL, clearAudio}) => {
  const mediaRecorder = useRef(null);
  const audioRef = useRef(null);

  // first load, get microphone and assign media recorder
  useEffect(() => {
    const getStream = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        mediaRecorder.current = new MediaRecorder(stream)
        mediaRecorder.current.addEventListener('dataavailable', (event) =>{
          if (event.data.size > 0) {
            const newAudioUrl = URL.createObjectURL(event.data); 
            setAudioURL(newAudioUrl);
          }
        });
    }
    getStream()
    .catch(err => console.log(err))
  }, [])

  // if is recording changes 
  useEffect(() => {
    if(mediaRecorder && mediaRecorder.current){
      if(isRecording && mediaRecorder.current.state === 'inactive'){
        mediaRecorder.current.start();
      } else if(mediaRecorder.current.state === 'recording'){
        mediaRecorder.current.stop();
      }
    }
  }, [isRecording])

  return (
    <div>
      {audioURL && (
        <div className='my-3'>
          <audio className='d-none' ref={audioRef} src={audioURL} controls />          
        </div>
      )}
      {audioURL && audioURL !== '' && <WaveformPlayer audioFileName={audioURL} clearAudio={clearAudio} isLocalLoad={true} />}
    </div>
  );
};

export default AudioRecorder;
