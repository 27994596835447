// import dependencies 
import React, { useState, useEffect} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// import assets
import arrowUp from '../img/arrow-up.svg'
import nodeGreenIcon from '../img/node-green.png'
import nodeRedIcon from '../img/node-red.png'
import nodeGreenIconSelected from '../img/node-green-selected.png'
import nodeRedIconSelected from '../img/node-red-selected.png'
import { useSelector } from 'react-redux';

const MapComponent = ({ installations = []}) => {

    // Hooks 
    const location = useLocation()
    const navigate = useNavigate()

    // Global State 
    const installationTypeSelected = useSelector(state => state.installationTypeSelected?.value)

    // Component States 
    const [displayType, setDisplayType] = useState()
    const [map, setMap] = useState(null)
    const [center,setCenter] = useState(DEFAULT_LOCATION)
    const [zoom,setZoom] = useState()

    // Depending on the url, display full map, half map, or no map 
    const determineDisplayType = () => {
        if (location.pathname.startsWith('/logs/node')) return 'quarter-screen-map'
        if (location.pathname.startsWith('/logs/map')) return 'full-screen-map'
        if (location.pathname.startsWith('/logs/view')) return ''
    }

    // on user cliking on marker, go to node page
    const handleMarkerClick = item => navigate('/logs/node/'+item.serialNum, {state: {node : item}})

    // Determine the icon that should be displayed
    const determineIcon = item => { 
        let iconUrl
        if(item.installationStatus?.successful || installationTypeSelected === 'store_room'){
            iconUrl = nodeGreenIcon
            if(item.serialNum === location.pathname.split('/')[3]){
                iconUrl = nodeGreenIconSelected
            }
        } else{
            iconUrl = nodeRedIcon
            if(item.serialNum === location.pathname.split('/')[3]){
                iconUrl = nodeRedIconSelected
            }
        }
        return iconUrl
    }
    
    // on maps first load, calculate the map bounds and center, according to nodes
    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();

        if(installations && installations?.length > 0){
          
            installations.forEach(marker => {
                const position = {
                    lat: marker.installationLatitude || marker.latitude, 
                    lng: marker.installationLongitude || marker.longitude
                }

                if(position.lat && position.lng){
                  bounds.extend(position);
                }

            });

            const center = bounds.getCenter()

            const newCenter = {lat : center.lat(),lng : center.lng()}

            if(bounds) map.fitBounds(bounds)
            if(newCenter) map.setCenter(newCenter)
            
          }
        setMap(map)
    }, [ installations])

    // Everytime url changes, determine the display stype of the map
    useEffect(() => {
        const type = determineDisplayType()
        setDisplayType(type)
    // eslint-disable-next-line
    }, [location])

    // if a node is selected, then center the map to the location of that node
    useEffect(() => {
        if(window.google && map){
        const selectedSerialNum = location.pathname.split('/')[3]
        const foundSelectedMarker = installations.find(x => x.serialNum === selectedSerialNum)

        if(foundSelectedMarker){
            const position = {
                lat: foundSelectedMarker.installationLatitude || foundSelectedMarker.latitude, 
                lng: foundSelectedMarker.installationLongitude || foundSelectedMarker.longitude
            }

            if(position.lat && position.lng){
              setCenter(position)
            } else{
              setCenter(DEFAULT_LOCATION)
            }
            

            setZoom(17)
        }
        }
    }, [ map, installations, location])

    return (
        <>
            <div 
                id="map" className={displayType}>
                <LoadScript
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        version="3.32">
                    <GoogleMap
                        ref={(ref) => setMap(ref)}
                        google
                        mapContainerStyle={mapStyles}
                        zoom={zoom}
                        center={center}
                        onLoad={onLoad}
                        options={mapOptions}>
                        {
                            installationTypeSelected === 'field_install' 
                            && window?.google 
                            && installations
                            && installations.length > 0 
                            && installations.map( (item, key) => {

                                const position = {
                                    lat: item.installationLatitude || item.latitude, 
                                    lng: item.installationLongitude || item.longitude
                                }

                                // if position is null, skip node 
                                if(!position.lat || !position.lng){
                                  return null
                                }
                                                                
                                let iconUrl = determineIcon(item)

                                return (
                                <Marker 
                                    key={'marker' + key} 
                                    icon={{
                                        url: iconUrl,
                                        anchor: new window.google.maps.Point(23, 23),
                                    }} 
                                    zIndex={item.serialNum === location.pathname.split('/')[3] ? 3 : 2}
                                    onClick={() => handleMarkerClick(item)}
                                    position={position}/>
                                )
                            })
                        }
                        </GoogleMap>
                    </LoadScript>

                {/* Display the button navigator if displaying on full map */}
                {displayType === 'full-screen-map' && (
                    <div className=''>
                        <div className='click-to-slide-up display-at-top'
                            style={{ position: 'absolute', top: 0 }} >
                            <Link to={'/logs/view'} className='d-flex justify-content-center 
                                text-light align-items-center text-decoration-none ps-'>
                                <span className='ps-4'>{t('list_view')}</span>
                                <img className="ubi-arrow" src={arrowUp} alt="ubi arrow up" />
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </>

    )
}

export default MapComponent
// map ubivu styles
const styles =  [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        { "visibility": "off" }
      ]
    }
]

// map css 
const mapStyles = {  
    height: "100%",
    width: "100%",
    position: 'relative',
    overflow: 'hidden'
};

// map options
const mapOptions = {
    streetViewControl: false , 
    styles,
    fullscreenControl: false ,
    zoomControl: false,
    maxZoom: 20,
    minZoom: 2,
    disableDefaultUI: true,
}

const DEFAULT_LOCATION = {lat: 26.12010954, lng: -80.1390082}
