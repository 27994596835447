// import dependencies 
import React from 'react'
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';

// import utils 
import { useOnlineStatus } from '../utils';

// import assets 
import checkSvg from '../img/check.svg'
import xSvg from '../img/x.svg'
import lineSvg from '../img/line.svg'

/**
 * 
 * @returns 
 */
const DtmCheckConnectionTable = ({selectedInstallation}) => {

    // constants, return html img element with correct picture 
    const checkImg = () => <img src={checkSvg} alt="" />
    const xImg = () => <img src={xSvg} alt="" />
    const lineImg = () => <img className='pe-1' src={lineSvg} alt="" />

    // hooks 
    const isOnline = useOnlineStatus();
    const appOnline = useSelector(state => state.appStatus)
    const location = useLocation()

    // global states
    const checkConnectionData = useSelector(state => state.checkConnection?.data)
    const installationTypeSelected = useSelector(state => state.installationTypeSelected?.value)

    const customerSelected = useSelector(state => state.customerSelected)


    // format tln number into tln format
    // ex : 7-5843-9601-1-0
    const formatTln = (tlnString) => {
        if(tlnString.length === 11){
            return tlnString.slice(0,1) + '-' +  
            tlnString.slice(1,5) + '-' + 
            tlnString.slice(5,9) + '-' + 
            tlnString.slice(9,10) + '-' + 
            tlnString.slice(10,11)
        } else{
            return tlnString
        }
    }
    
    // helper function to format date
    const formatDate = str => {
        if(!str) return lineImg()
        return new Date(str).toLocaleDateString('en-us', { year:"numeric", month:"numeric", day:"numeric"})
    }

    return (
        <div className="table-con-check-container">
            <table className="table my-4 text-primary">
                <tbody>

                    {installationTypeSelected === 'store_room' && (
                    <>
                    <tr>
                        <td>{t('serial_num')}</td>
                        <td className='text-end text-nowrap pe-0'>
                            {selectedInstallation && selectedInstallation.serialNum}
                        </td>
                    </tr>
                    <tr>
                        <td>{t('date_installed')}</td>
                        <td className='text-end text-nowrap pe-0'>
                            {selectedInstallation && formatDate(selectedInstallation.dateInstalled)}
                        </td>
                    </tr>
                    </>
                    )}
                    <tr>
                        <td>{t('dtm_table_connection')}</td>
                        <td className='text-end pe-0'>
                            {/* if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                            {!isOnline || !appOnline || !checkConnectionData ? lineImg() :
                                checkConnectionData?.connections?.connection === null ? lineImg() :
                                    checkConnectionData?.connections?.connection ? checkImg() : xImg()
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>{t('dtm_table_gps')}</td>
                        <td className='text-end pe-0'>
                            {/* if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                            {!isOnline || !appOnline || !checkConnectionData ? lineImg() :
                                checkConnectionData?.connections?.gps === null ? lineImg() :
                                    checkConnectionData?.connections?.gps ? checkImg() : xImg()
                            }
                        </td>
                    </tr>
                    {selectedInstallation && (
                        <tr>
                        <td>{t('kVA')}</td>
                        <td className='text-end text-nowrap pe-1'>
                            {/*if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                            {selectedInstallation.kva ? selectedInstallation?.kva : selectedInstallation.kvaOverride ? selectedInstallation.kvaOverride : '' }
                        </td>
                    </tr>
                    )}
                    <tr>
                        <td>{t('dtm_table_primary_data')}</td>
                        <td className='text-end pe-0'>
                            {/*if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                            {!isOnline || !appOnline || !checkConnectionData ? lineImg() :
                                checkConnectionData?.connections?.primary_data === null ? lineImg() :
                                    checkConnectionData?.connections?.primary_data ? checkImg() : xImg()
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className='text-nowrap'>{t('dtm_table_secondary_data')}</td>
                        <td className='text-end pe-0'>
                            {/*if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                            {!isOnline || !appOnline || !checkConnectionData ? lineImg() :
                                checkConnectionData?.connections?.secondary_data === null ? lineImg() :
                                    checkConnectionData?.connections?.secondary_data ? checkImg() : xImg()
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className='text-nowrap'>{t('dtm_table_pressure_sensor')}</td>
                        <td className='text-end pe-0'>
                            {/*if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                            {!isOnline || !appOnline || !checkConnectionData ? lineImg() :
                                checkConnectionData?.connections?.pressure_sensor === null ? lineImg() :
                                    checkConnectionData?.connections?.pressure_sensor ? checkImg() : xImg()
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>{t('dtm_table_rogo')}</td>
                        <td className='text-end pe-0'>
                            {/*if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                            {!isOnline || !appOnline || !checkConnectionData ? lineImg() :
                                checkConnectionData?.connections?.rogowski_placement === null ? lineImg() :
                                    checkConnectionData?.connections?.rogowski_placement ? checkImg() : xImg()
                            }
                        </td>
                    </tr>

                    {installationTypeSelected === 'field_install' && customerSelected.isFPL && (
                        <>
                            <tr>
                                <td>{t('sc_relay')}</td>
                                <td className='text-end pe-0'>
                                    {/*if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                                    {!isOnline || !appOnline || checkConnectionData?.connections?.connection === false ? lineImg()  :
                                        checkConnectionData?.connections?.s_c_relay === null ? lineImg() :
                                            checkConnectionData?.connections?.s_c_relay ? checkImg() : xImg()
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{t('temp_sensor')}</td>
                                <td className='text-end pe-0'>
                                    {/*if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                                    {!isOnline || !appOnline || checkConnectionData?.connections?.connection === false ? lineImg() :
                                        checkConnectionData?.connections?.temperature_sensor === null ? lineImg() :
                                            checkConnectionData?.connections?.temperature_sensor ? checkImg() : xImg()
                                    }
                                </td>
                            </tr>
                        </>
                    )}

                    {location.pathname.startsWith('/logs/') && installationTypeSelected === "field_install" && (
                        <tr>
                           
                            <td>{t('tln')}</td>
                            <td className='text-end text-nowrap pe-0'>
                                {/*if app is not online or if no data, dash , if prop is null, dash, if prop is true, check, else X */}
                                {selectedInstallation?.tln ? formatTln(selectedInstallation.tln) : lineImg()}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default DtmCheckConnectionTable
