export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'

export const APP_ONLINE = 'APP_ONLINE'
export const APP_OFFLINE = 'APP_OFFLINE'

export const GLOBAL_LOADER_SHOW = 'GLOBAL_LOADER_SHOW'
export const GLOBAL_LOADER_HIDE = 'GLOBAL_LOADER_HIDE'

export const USER_LOGOUT= 'USER_LOGOUT'
export const USER_CLEAR_JUST_LOGGED_IN_SET = 'USER_CLEAR_JUST_LOGGED_IN_SET'
export const USER_CLEAR_JUST_LOGGED_IN_CLEAR = 'USER_CLEAR_JUST_LOGGED_IN_CLEAR'

export const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST'
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS'
export const CUSTOMER_LIST_FAIL = 'CUSTOMER_LIST_FAIL'
export const CUSTOMER_LIST_RESET = 'CUSTOMER_LIST_RESET'

export const MNS_LIST_REQUEST = 'MNS_LIST_REQUEST'
export const MNS_LIST_SUCCESS = 'MNS_LIST_SUCCESS'
export const MNS_LIST_DEFAULT = 'MNS_LIST_DEFAULT'
export const MNS_LIST_FAIL = 'MNS_LIST_FAIL'
export const MNS_LIST_RESET = 'MNS_LIST_RESET'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET'

export const CUSTOMER_SELECTED_SET = 'CUSTOMER_SELECTED_SET'
export const CUSTOMER_SELECTED_CLEAR = 'CUSTOMER_SELECTED_CLEAR'

export const INSTALLATION_TYPE_SET = 'INSTALLATION_TYPE_SET'
export const INSTALLATION_TYPE_CLEAR = 'INSTALLATION_TYPE_CLEAR'

export const CHECK_CONNECTION_REQUEST = 'CHECK_CONNECTION_REQUEST'
export const CHECK_CONNECTION_SUCCESS = 'CHECK_CONNECTION_SUCCESS'
export const CHECK_CONNECTION_FAIL = 'CHECK_CONNECTION_FAIL'
export const CHECK_CONNECTION_RESET = 'CHECK_CONNECTION_RESET'

export const SUBMIT_INSTALLATION_REQUEST = 'SUBMIT_INSTALLATION_REQUEST'
export const SUBMIT_INSTALLATION_SUCCESS = 'SUBMIT_INSTALLATION_SUCCESS'
export const SUBMIT_INSTALLATION_FAIL = 'SUBMIT_INSTALLATION_FAIL'
export const SUBMIT_INSTALLATION_RESET = 'SUBMIT_INSTALLATION_RESET'

export const SUBMIT_NOTES_REQUEST = 'SUBMIT_NOTES_REQUEST'
export const SUBMIT_NOTES_PARTIAL = 'SUBMIT_NOTES_PARTIAL'
export const SUBMIT_NOTES_SUCCESS = 'SUBMIT_NOTES_SUCCESS'
export const SUBMIT_NOTES_FAIL = 'SUBMIT_NOTES_FAIL'
export const SUBMIT_NOTES_RESET = 'SUBMIT_NOTES_RESET'

export const NODE_REQUEST = 'NODE_REQUEST'
export const NODE_SUCCESS = 'NODE_SUCCESS'
export const NODE_FAIL = 'NODE_FAIL'
export const NODE_RESET = 'NODE_RESET'

export const INSTALLATION_LIST_REQUEST = 'INSTALLATION_LIST_REQUEST'
export const INSTALLATION_LIST_SUCCESS = 'INSTALLATION_LIST_SUCCESS'
export const INSTALLATION_LIST_ADD = 'INSTALLATION_LIST_ADD'
export const INSTALLATION_LIST_FAIL = 'INSTALLATION_LIST_FAIL'
export const INSTALLATION_LIST_RESET = 'INSTALLATION_LIST_RESET'

export const INSTALLATION_REPORT_REQUEST = 'INSTALLATION_REPORT_REQUEST'
export const INSTALLATION_REPORT_SUCCESS = 'INSTALLATION_REPORT_SUCCESS'
export const INSTALLATION_REPORT_ADD = 'INSTALLATION_REPORT_ADD'
export const INSTALLATION_REPORT_FAIL = 'INSTALLATION_REPORT_FAIL'
export const INSTALLATION_REPORT_RESET = 'INSTALLATION_REPORT_RESET'

export const INSTALLATION_UNPAIR_REQUEST = 'INSTALLATION_UNPAIR_REQUEST'
export const INSTALLATION_UNPAIR_SUCCESS = 'INSTALLATION_UNPAIR_SUCCESS'
export const INSTALLATION_UNPAIR_FAIL = 'INSTALLATION_UNPAIR_FAIL'
export const INSTALLATION_UNPAIR_RESET = 'INSTALLATION_UNPAIR_RESET'

export const OFFLINE_INSTALLATION_LIST_REQUEST = 'OFFLINE_INSTALLATION_LIST_REQUEST'
export const OFFLINE_INSTALLATION_LIST_SUCCESS = 'OFFLINE_INSTALLATION_LIST_SUCCESS'
export const OFFLINE_INSTALLATION_LIST_FAIL = 'OFFLINE_INSTALLATION_LIST_FAIL'
export const OFFLINE_INSTALLATION_LIST_RESET = 'OFFLINE_INSTALLATION_LIST_RESET'

export const OFFLINE_INSTALLATION_ADD_REQUEST = 'OFFLINE_INSTALLATION_ADD_REQUEST'
export const OFFLINE_INSTALLATION_ADD_SUCCESS = 'OFFLINE_INSTALLATION_ADD_SUCCESS'
export const OFFLINE_INSTALLATION_ADD_FAIL = 'OFFLINE_INSTALLATION_ADD_FAIL'
export const OFFLINE_INSTALLATION_ADD_RESET = 'OFFLINE_INSTALLATION_ADD_RESET'

export const OFFLINE_INSTALLATION_REMOVE_REQUEST = 'OFFLINE_INSTALLATION_REMOVE_REQUEST'
export const OFFLINE_INSTALLATION_REMOVE_SUCCESS = 'OFFLINE_INSTALLATION_REMOVE_SUCCESS'
export const OFFLINE_INSTALLATION_REMOVE_FAIL = 'OFFLINE_INSTALLATION_REMOVE_FAIL'
export const OFFLINE_INSTALLATION_REMOVE_RESET = 'OFFLINE_INSTALLATION_REMOVE_RESET'