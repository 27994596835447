// import dependencies 
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'


/**
 * 
 * @param {Function} handleUnableToScan 
 * @returns 
 */
const UnableToScan = ({handleUnableToScan, handleGoBack}) => {

    // component state 
    const [manualInput, setManualInput] = useState('')
    const [readyToSubmit, setReadyToSubmit] = useState(false)

    // if input is longer that 4 digits, user can submit
    const handleSubmit = () => {
        if(manualInput.length > 4) handleUnableToScan(manualInput)
    }

    // if input is longer that 4 digits, user can continue
    useEffect(() => {
        if(manualInput.length > 4){
            setReadyToSubmit(true)
        } else{
            setReadyToSubmit(false)
        }
    }, [manualInput])

  return (
    <div className='full-page container'>
        <div>
            <p>{t('unable_to_scan_text1')}</p>
        </div>

        <div className='input-group my-5'>
            <input type="text" className="ubi-text-input" id="email" 
                placeholder="2102UTS006751" value={manualInput} 
                maxLength={18}
                onChange={e => setManualInput(e.target.value.toUpperCase())}/>
        </div>

        <div className='d-flex justify-content-around' style={{gap : '10px'}}>
            <button
              onClick={handleGoBack}
              className={`btn btn-secondary standard-btn fw-bold big-padding`}>{t('back')}</button>

            <button
              onClick={handleSubmit}
              className={`btn btn-primary standard-btn fw-bold big-padding ${!readyToSubmit && 'btn-disabled'}`}>{t('continue')}</button>
        </div>

    </div>
  )
}

export default UnableToScan