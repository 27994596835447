// import dependencies 
import React, { useEffect } from 'react'
import * as cn from '../redux/constants'
import { useSelector, useDispatch } from 'react-redux'
import { t } from 'i18next'

// import assets 
import loop from '../img/loop.png'

// import utils 
import { useOnlineStatus } from '../utils'

/**
 * 
 * @returns 
 */
const GlobalLoader = ({forceShow = false}) => {

  // hooks 
  const isOnline = useOnlineStatus();
  const dispatch = useDispatch()

  // global state 
  const showGlobalLoader = useSelector(state => state.globalLoader) 

  // Import loaders 
  const { loading: submitInstallationLoading } = useSelector(state => state.submitInstallation)
  const { loading: userLoginLoading } = useSelector(state => state.userLogin)
  const { loading: customerListLoading } = useSelector(state => state.customerList)
  const { loading: checkConnectionLoading } = useSelector(state => state.checkConnection)
  const { loading: installationListLoading } = useSelector(state => state.installationList)
  const { loading: nodeInfoLoading } = useSelector(state => state.nodeInfo)
  const { loading: changePasswordLoading } = useSelector(state => state.changePassword)

  // handle online status change 
    useEffect(() => {
      if(isOnline){
        dispatch({
          type: cn.APP_ONLINE
        })
      } else{
        dispatch({
          type: cn.APP_OFFLINE
        })
      }
    },[isOnline, dispatch])

  return (
    // Show gloabal loader if any of these loaders are true
    <div id="global-loader">
      {(showGlobalLoader || 
      submitInstallationLoading || userLoginLoading || 
      customerListLoading || checkConnectionLoading || 
      installationListLoading || nodeInfoLoading || 
      changePasswordLoading || forceShow ) && 
        <>
          <div className='overlay'>
          </div>
          <div className='custom-spinner position-fixed top-50 start-50'>
              <span>{t('loading')}</span>
              <img src={loop} alt="" />
          </div>
        </>
      }
    </div>
  )
}

export default GlobalLoader