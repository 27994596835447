// import dependencies 
import React, { useRef, useState } from 'react'
import Camera from 'react-html5-camera-photo';
import { t } from 'i18next';
import { blobToBase64, compressImage } from '../utils';
import { useLocation } from 'react-router-dom';

/**
 * 
 * @param {Function} submit 
 * @returns 
 */
const TakeInstallPic = ({submit, text, icon, cameraErrorDetected, setCameraErrorDetected}) => {

    // component state 
    const [installPic, setInstallPic] = useState(null)
    const inputFileRef = useRef(null)
    const [file, setFile] = useState()

    // compress and submit 
    const handleSubmit = async () => {
        if(installPic){
            submit(installPic)
            setInstallPic(null)
        }   
    }

    const handleSubmitUploadPhoto = async() => {
        const file64 = await blobToBase64(file)
        const compressedImage = await compressImage(file64)
        submit(compressedImage)
        setFile(null)
    }

    const handleCameraError = (err) => {
        if(err){
            setCameraErrorDetected(true)
        }
    }

    const handleCameraErrorTextTranslation = (inputText) => {
        switch (inputText) {
            case "transformer_plate_text":
                return'transformer_plate_text_add'
            case "unit_photo_text":
                return'unit_photo_text_add'
            case "primaries_photo_text":
                return'primaries_photo_text_add'
            case "secondaries_photo_text":
                return'secondaries_photo_text_add'
            case "prv_photo_text":
                return'prv_photo_text_add'
            case "pairing_photo_text":
                return'pairing_photo_text_add'
            default:
                return inputText
        }
    }

    // Take install Picture, live feed 
    if(!cameraErrorDetected){
        return (
            <div className={`full-page ${installPic ? 'installed-unit' : ''}`}>
                
                <div className='camera-container'>
                        <div className={`camera ${installPic ? 'd-none' : ''}`}>
                            <Camera idealFacingMode="environment" 
                            onTakePhoto={(dataUri) => { setInstallPic(dataUri)}}
                            onCameraError={handleCameraError}
                            sizeFactor={0.90}
                            isMaxResolution={true}
                            imageType={'jpg'}
                            imageCompression={0.7}
                            isDisplayStartCameraError={true}
                            />
                            {/* Container for CSS blur  */}
                            <div className="gradient-container">
                                <div className='position-absolute d-flex justify-content-between align-items-center' style={
                                    { top: icon ? "4%" : "8%", left: "4%", width : "calc(100% - 30px)", verticalAlign: "center",
                                    paddingLeft : '13px', paddingRight : '13px', gap: '10px'}}>
                                    <p className='text-light px-1 m-0'>{t(text)}</p>
                                    {icon && <img className='align-middle' src={icon} alt="icon"/>}
                                </div>
                            </div>
    
                            {/* Retacle (White bars) */}
                            <div>
                                <div className="retacle top left horizontal"></div>
                                <div className="retacle top left vertical"></div>
                                <div className="retacle top right vertical"></div>
                                <div className="retacle top right horizontal"></div>
                                <div className="retacle bottom left horizontal"></div>
                                <div className="retacle bottom left vertical"></div>
                                <div className="retacle bottom right vertical"></div>
                                <div className="retacle bottom right horizontal"></div>
                            </div>
                        </div>
                        {/* After taking picture, display retake & accept btns */}
                        <div className={`container ${installPic ? '' : 'd-none'}`}>
                            <div className="camera-show-picture">
                                <img src={installPic} alt="pic" className='img-fluid'/>
                                <div className=" d-flex justify-content-around mt-4">
                                    <button className='btn btn-secondary text-primary fw-bold standard-btn' onClick={() => setInstallPic(null)}>{t('retake')}</button>
                                    <button className='btn btn-primary text-light fw-bold standard-btn' onClick={handleSubmit}>{t('accept')}</button>
                                </div>
                                <br />
    
                            </div>
                        </div>
                </div>
            </div>
        )
    } else{
        // Take install picture Natively
        return (
            <div className='full-page container'>
                <div className='d-flex justify-content-between align-items-center mb-4' style={{marginLeft : '-10px', marginRight: '-10px'}}>
                    <p className='mb-0 me-2'>{!cameraErrorDetected ? t(text) : t(handleCameraErrorTextTranslation(text)) }</p>
                    <img style={{borderRadius: '50%', border: '1px solid black'}}  src={icon} alt="icon"/>
                </div>

                <div className="text-center my-3">
                    {!file && <button className="btn btn-primary standard-btn fw-bold" 
                        onClick={() => {inputFileRef.current.click()}}>{t('add_photo')}</button>}
                </div>


                <input className='d-none' accept="image/*" 
                    ref={inputFileRef} type="file" onChange={e => {setFile(e.target.files[0])}}
                />
                
                {file && <div className='text-center' >
                    <img className='img-fluid rounded' style={{maxHeight : '45vh'}} src={URL.createObjectURL(file)}></img>
                    <div className='d-flex justify-content-around mt-4'>
                        <button className="btn btn-secondary standard-btn fw-bold" onClick={() => {inputFileRef.current.click()}}>{t('Replace')}</button>
                        <button className="btn btn-primary standard-btn fw-bold" onClick={handleSubmitUploadPhoto}>{t('continue')}</button>
                    </div>
                </div>}

            </div>
        )
    }
}

export default TakeInstallPic