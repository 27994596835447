// import dependencies 
import React , { useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import utils 
import { encryptPass } from '../utils';

// import assets
import eye from '../img/eye.svg'
import eye_dash from '../img/eye-dash.svg'
import profile2 from '../img/profile-2.svg'
import bottomLogo from '../img/powered-ubicquia_dark.svg'

// import components
import BottomAlert from '../components/BottomAlert';

// import API redux actions
import { login } from '../redux/actions';
import Select, { components } from 'react-select';
import arrowDown from '../img/arrow-down.svg'


const Login = () => {

  // Hooks 
  const passRef = useRef()
  const eyeRef = useRef()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  // Component State
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [enableLoginBtn, setEnableLoginBtn] = useState(false)

  const getSelectedLanguage = () => {
    const lang = LANGUAGES.find(x => x.value === i18n.language)
    if(lang) return lang
    
    // default english
    return LANGUAGES[0]
  }

  const [selectedLanguage, setSelectedLanguage] = useState(getSelectedLanguage());

  // Global State 
  const userLoginError = useSelector(state => state.userLogin?.error)

  // On submit, encrypt password and call the login api 
  const onSubmit = e => {
    e.preventDefault()
    const encryptedCredentials = encryptPass({email, password})
    dispatch(login(encryptedCredentials))
  }

  // Handle show/hide password, show different asset depending on state
  const handleShowPass = () => {
    if(passRef.current.type === 'password' && password.length > 0){
      passRef.current.type = 'text'
      eyeRef.current.src = eye
    } else{
      passRef.current.type = 'password'
      eyeRef.current.src = eye_dash
    }
  }

  // dropdown indicator for react-select
  const DropdownIndicator = props => {
    return <components.DropdownIndicator {...props}><img src={arrowDown}/></components.DropdownIndicator>
  }

  const handleSelectLanguage = lang => {
    setSelectedLanguage(lang)
    i18n.changeLanguage(lang.value)
  }

    // enable/disable login button depending if an email is entered or not
    useEffect(() => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      setEnableLoginBtn(emailRegex.test(email) && password.length > 3)
    }, [email, password])
  

  return (
    <>
    {/* Show login error */}
    { userLoginError &&  <BottomAlert text={t(userLoginError || 'offline_login' )}/>}

    <div className={`container d-flex flex-column justify-content-start bg-light`} id="login-page" style={i18n.language === 'es' ? {height: 'calc(100% - 280px)'}: {}}> 
        <form onSubmit={onSubmit}>
        <div className='mb-2'>
          <p className='fs-1 text-primary fw-bold m-0'>{t('login')}</p>
        </div>
        
          <div className="mb-3">
              <input type="email" className="ubi-text-input" id="email" placeholder={t('enter_email')} 
                value={email} onChange={e => setEmail(e.target.value)}/>
          </div>

          <div className="mb-3 showpass-btn">
              <input type="password" ref={passRef} className="ubi-text-input" id="password" placeholder={t('enter_password')} 
              value={password} onChange={e => setPassword(e.target.value)}/>
              <button type="button" onClick={handleShowPass}>
                <img alt="Hide/Show Password" className={password.length > 0 ? 'opacity-100' : 'opacity-50'} src={eye_dash} ref={eyeRef}/>
              </button>
          </div>

          <div className="mb-3">
                  <Select defaultValue={selectedLanguage}
                        className={'react-select-container'}
                        components={{DropdownIndicator}}
                        classNamePrefix={'react-select'}
                        isSearchable={false}
                        onChange={handleSelectLanguage}
                        options={LANGUAGES}/>
          </div>

          <div className="mb-4 text-center" >
            <Link style={{color : '#3FB3FB'}} className='text-decoration-none' to={"/forgot-password"}>{t('forgot_password')}</Link>
          </div>

          <div className='text-center'>
              <button type="submit" className={`btn btn-primary btn-block my-1 p-standard py-2 login-btn fw-bold ${(enableLoginBtn) ? '' :'btn-disabled'}`}>
                <img src={profile2} alt="account"/> 
                <span>{t('login')}</span>
              </button>
              <br />
          </div>
        </form>

        <div className='img-container company-logo text-end mt-4 me-n4'>
          <img src={bottomLogo} alt="Powered by Ubicquia"/>
        </div>
    </div>
    </>
  )
}

export default Login


const LANGUAGES = [
  {
    value: 'en',
    label : 'English'
  }, 
  {
    value: 'es',
    label : 'Spanish'
  }, 

]
