// import dependencies 
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useLocation  } from "react-router-dom";
import { AnimatePresence } from "framer-motion"

// import home components 
import ViewLogs from '../homepage-components/ViewLogs';
import HomeComponent from '../homepage-components/HomeComponent';
import MapComponent from '../homepage-components/MapComponent';
import DtmPage from '../homepage-components/DtmNodePage';

// import API redux actions 
import { getInstallations, getOfflineInstalls, submitInstallation } from '../redux/actions'
import { db } from '../db';

const Home = () => {

  // Hooks 
  const dispatch = useDispatch()
  const location = useLocation()

  // Component States
  const [showMap, setShowMap] = useState(false)
  const [selectedInstallation, setSelectedInstallation] = useState(null)

  // Global States
  const appStatus = useSelector(state => state.appStatus)
  const { user } = useSelector(state => state.userLogin)
  const { installations } = useSelector(state => state.installationList)
  const installationSubmitted = useSelector(state => state.submitInstallation.installation)
  const submitInstallationError = useSelector(state => state.submitInstallation.error)
  const unPairedInstallation = useSelector(state => state.installationUnpair.unpaired)
  const customerSelected = useSelector(state => state.customerSelected)
  const installationTypeSelected = useSelector(state => state.installationTypeSelected?.value)
  const {installations : offlineInstalls} = useSelector(state => state.offlineInstallationList)

  useEffect(() => {
    dispatch(getOfflineInstalls())
  }, [location, dispatch, appStatus])

  // if there are offline installs and status is online try to submit 
  useEffect(() => {
    const handle = async () => {
      if(offlineInstalls?.length > 0 && appStatus.online ){
        for (const offlineInstall of offlineInstalls) {
          await dispatch(submitInstallation(offlineInstall, {isOfflineInstall: true}))
        }
      }
    } 
    handle()
  }, [offlineInstalls, appStatus, dispatch])
    
  
  // load map if any of this urls are accessed, 
  // give 1 sec of time out to avoid page aniation glitch
  useEffect(() => {
    if(location.pathname === '/logs/view' || 
       location.pathname === '/logs/map' || 
       location.pathname.startsWith('/logs/node')){

      setTimeout(() => {
        setShowMap(true)
      }, 1000);

    } else{
      setShowMap(false)
    }

  }, [location])

  useEffect(() => {

    // custom function so it only calls installtion API once 
    const handle = () => {
      // Get installations automatically if they are null
      if (!installations) {
        dispatch(getInstallations())
        return
      }

      // if installation submit is success, then remove from storage & reset installation state
      if (installationSubmitted || submitInstallationError) {
        dispatch({ type: 'SUBMIT_INSTALLATION_RESET' })
        dispatch(getInstallations())
        return
      }

      // get activations if recently unpaired
      if (unPairedInstallation) {
        dispatch(getInstallations())
        return
      }

      // get activations if we changed customers 
      if(customerSelected){
        dispatch({ type: 'SUBMIT_INSTALLATION_RESET' })
        dispatch(getInstallations())
        return
      }
    }
    
    if(user && customerSelected){
      handle()
    }
    
  // eslint-disable-next-line
  }, [customerSelected, unPairedInstallation, 
    installationSubmitted, customerSelected, 
    user, submitInstallationError, dispatch])

  return (
    <>
      {/* Map Goes here to display in the background */}
      {/* Load map, when it is a field install or when it is a store room install and user is on node page  */}
      { (showMap && (installationTypeSelected === "field_install" || location.pathname.startsWith('/logs/node')) ) 
      && <MapComponent installations={installations} />}

      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          {/* Home Page */}
          <Route path="/" element={<HomeComponent installations={installations} path={location.pathname} offlineInstalls={offlineInstalls}/>}/>

          {/* Logs Page  */}
          <Route path="/logs/view" element={<ViewLogs installations={installations} path={location.pathname}/>}/>

          {/* Node Page */}
          <Route path="/logs/node/*" element={
            <DtmPage 
              selectedInstallation={selectedInstallation} 
              setSelectedInstallation={setSelectedInstallation} 
              installations={installations}
              installationSubmitted={installationSubmitted}
            />}/>
        </Routes>
      </AnimatePresence>
    </>

  )
}
export default Home