import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import trash from '../img/trash.svg'

const UserPromptStyled = styled.div`
  .main-user-prompt{
    z-index : 4;
    opacity : 0.99;
    animation : fadePrompt 500ms
  }

  .insideFullpage{
    top: -80px !important;
    left: 0px !important;
    height: calc(100% + 80px) !important;
  }  

  @keyframes fadePrompt {
    0%   {opacity: 0}
    50%  {opacity: 0.25}
    75%  {opacity: 0.75}
    100% {opacity: 0.95}
  }
`

const UserPrompt = ({data : { questionTitle, questionText, agreeText = 'Yes', disagreeText = 'Cancel', onAgree, onDisagree, disableAgreeBtn, fullWidth}}  ) => {
  return (
    <UserPromptStyled>
      <div className='main-user-prompt position-absolute bg-primary h-100 w-100 top-0'></div>
      <div className={`prompt-area position-absolute start-50 translate-middle ${fullWidth ? 'full-width' : ''}`} style={{zIndex: 5}}>
        <div className='bg-light rounded-5 text-primary'>
            <div className="" role="status">
              <span className="fs-1 fw-bold">{questionTitle}</span>
              <br />
              <div className='my-3' style={{whiteSpace: 'pre-wrap'}}>{questionText}</div>
            </div>
            <div className={`d-flex gap-3 ${onAgree && onDisagree ? 'justify-content-around fixed-width-btns' : 'justify-content-center'}`}>
                {onDisagree && <button onClick={onDisagree} className='btn btn-secondary fw-bold standard-btn text-nowrap'>{disagreeText}</button>}
                <button onClick={onAgree} className={`btn btn-primary fw-bold standard-btn text-nowrap ${disableAgreeBtn ? 'btn-disabled' : ''}`}>{agreeText}</button>
            </div>
        </div>
      </div>    
    </UserPromptStyled>
  )
}

export const TextNotePrompt = ({ title, placeholder = t('begin_typing'), maxInput = 500, onCancel, onDone, defaultValue, insideFullPage, inputPhoto }) => {
  const photoInput = useRef(null)
  const [text, setText] = useState(defaultValue ? defaultValue : '');
  const [photo, setPhoto] = useState(inputPhoto ? inputPhoto.value : null)

  const handleAddPhoto = () => {
    photoInput?.current?.click()
  }

  const handlePhotoUpload = async (e) => {
    if(!e.target.files) return 
    const file = await toBase64(e?.target?.files[0])
    setPhoto(file)
  }  

  const handleInput = (text) => {
    if (text.length > maxInput) return;
    setText(text);
  };

  const clearPhoto = () => {
    photoInput.current.value = null
    setPhoto(null)
  }

  return (
    <UserPromptStyled>
      <div className={`main-user-prompt text-note-prompt position-absolute bg-primary h-100 w-100 top-0 ${insideFullPage ? 'insideFullpage' : ''}`}>
        <div className={`prompt-area position-absolute start-50 translate-middle`}>
          <div className='rounded-5 text-primary' style={{backgroundColor: 'white'}}>
            <p className='page-card-title'>{title}</p>
            <textarea className="ubi-text-area" id="" cols={30} rows={6} placeholder={placeholder} value={text} onChange={e => handleInput(e.target.value)}>
            </textarea>
            <span className='ubi-text-area-counter'>{text.length}/{maxInput}</span>
            <div className="d-flex justify-content-between mx-3 mt-4">
              <button className={`ubi-button secondary-btn text-nowrap`} onClick={onCancel}>{t('cancel')}</button>
              <button className={`ubi-button text-nowrap ${text.length < 1 ? 'disabled-btn' : ''} `} onClick={() => onDone(text, photo)}>{t('done')}</button>
            </div>

            <div className="d-flex justify-content-between mt-4 mx-3">
              <button className={`ubi-button primary-btn text-nowrap w-100 ${photo ? 'disabled-btn' : ''}`} onClick={handleAddPhoto}>{t('add_photo')}</button>
            </div>
            {/* display image */}
            {photo && <div className='d-flex mt-3 mx-3'>
              <div className='position-relative' style={{maxWidth: '125px', maxHeight: '180px'}}>
                <img className='img-fluid rounded shadow-sm' src={photo} alt="" style={{maxHeight: '200px'}} />
                <button className='d-block position-absolute top-0 end-0 bg-transparent border-0 pt-1' onClick={clearPhoto}>
                  <img className='delete-btn' src={trash} alt=""/>
                </button>
              </div>
            </div>}
            
            {/* photo input */}
              <input className='d-none' ref={photoInput} type="file" name="myImage" accept="image/*" onChange={handlePhotoUpload}/>
          </div>
        </div>
      </div>
    </UserPromptStyled>);
};

export default UserPrompt

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});