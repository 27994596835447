// import dependencies 
import * as cn from '../redux/constants'
import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { DropdownIndicator } from '../utils';
import Select from 'react-select';

// import utils 
import { encryptPass } from '../utils'

// import components
import UserPrompt from '../components/UserPrompt'
import BottomAlert from '../components/BottomAlert'

// import API redux actions 
import { changePassword, getCustomers } from '../redux/actions'

const Account = () => {

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Component States 
    const [showChangePassPrompt, setShowChangePassPrompt] = useState(false)
    const [oldPassword,setOldPassword ] = useState('')
    const [password,setPassword ] = useState('')
    const [passwordConfirmation,setPasswordConfirmation ] = useState('')

    // Global States 
    const { user } = useSelector(state => state.userLogin)
    const { customers } = useSelector(state => state.customerList)
    const changePasswordError = useSelector(state => state.changePassword?.error)
    const customerSelected = useSelector(state => state.customerSelected)
    const installationTypeSelected = useSelector(state => state.installationTypeSelected)

    // On select customer from dropdown, find customer on array, and save it on global state
    const handleSelectCustomer = cust => {
        const foundCustomer = customers.find(customer => customer.customer_id === cust.customer_id)
        dispatch({
            type: cn.CUSTOMER_SELECTED_SET,
            payload : foundCustomer
        })
        navigate('/')
    }

    // On select install type, find it on install type array and save it on global state 
    const handleSelectInstallType = installType => {
        dispatch({
            type: cn.INSTALLATION_TYPE_SET,
            payload : installType
        })
        navigate('/')
    }

    // Handle user response to change password prompt, 
    // if submit = agree, encrypt new password, dispatch api and clear state
    const handleChangePasswordRes = (submit) => {
        const email = user.email
        
        if(submit){
            const toEncrypt = {email,oldPassword,password,
                password_confirmation: passwordConfirmation
            }
            const encryptedCredentials = encryptPass(toEncrypt)
    
            dispatch(changePassword(encryptedCredentials))
        } 
        setPassword('')
        setPasswordConfirmation('')
        setShowChangePassPrompt(false)
    }

    // On first load, if customers arr is empty or undefined, dispatch get customers API 
    useEffect(() => {
        if(!customers) dispatch(getCustomers())
        if(customers && customers.length < 1) dispatch(getCustomers())
    // eslint-disable-next-line
    }, [])


  return (
    <>
        <div className='full-page account-page text-primary' style={{top: '80px'}}>
            <div className='h-100 container'>
                {/* Change Password */}
                <div>
                    <p className="fs-2 fw-bold text-primary">{t('change_password_title')}</p>
                    <p>{t('change_password')}</p>
                </div>
                <div className='input-group my-4'>
                    <div className="mb-3 w-100">
                        <input type="email" className="ubi-text-input" value={user ? user.email : ''} readOnly
                        id="email2" autoComplete="off" placeholder={t('enter_email')}/>
                    </div>
                    <div className="mb-3 w-100">
                        <input type="password" className="ubi-text-input" value={oldPassword} onChange={e => setOldPassword(e.target.value)}
                        id="password2" autoComplete="new-password" placeholder={t('change_password')}/>
                    </div>
                </div>
                <div className='text-center'>
                    <button onClick={setShowChangePassPrompt} 
                    className={`btn btn-primary standard-btn fw-bold ${ oldPassword.length < 8 ? 'btn-disabled' : ''}`}>
                        {t('change_password_title')}
                    </button>
                </div>

                {/* Change Customer */}
                <div className='mt-5'>
                    <p className="fs-2 fw-bold text-primary">{t('change_customer_title')}</p>
                    <p>{t('change_customer')}</p>
                </div>

                <div className='my-5'>
                    {customers && customers.length > 0 ? (
                        <>
                        <Select
                            defaultValue={customerSelected}
                            className={'react-select-container'}
                            noOptionsMessage={() => t('no_customers')}
                            components={{DropdownIndicator}}
                            classNamePrefix={'react-select'}
                            isSearchable={true}
                            placeholder={t('choose_customer')}
                            onChange={handleSelectCustomer}
                            getOptionLabel={option => option.name + ' (' + option.customer_id + ")"}
                            getOptionValue={option => option.customer_id}
                            options={customers.sort((a,b) => a.name.localeCompare(b.name))}/>
                        </>
                    ) : customerSelected ? (<select className="form-select ubi-select-input mb-3">
                        <option value="default">{customerSelected.name} ({customerSelected.customer_id})</option>
                        </select>) : <div className='text-center'><p>{t('no_customers_found')}</p></div>
                    }
                </div>

                {/* Change Installtion Type */}
                <div className='mt-5'>
                    <p className="fs-2 fw-bold text-primary">{t('change_installation_type_title')}</p>
                    <p>{t('change_customer')}</p>
                </div>

                <div className='my-5'>
                    <>
                    <Select
                            defaultValue={installationTypeSelected}
                            className={'react-select-container'}
                            components={{DropdownIndicator}}
                            classNamePrefix={'react-select'}
                            isSearchable={false}
                            placeholder={t('choose_customer')}
                            onChange={handleSelectInstallType}
                            getOptionLabel={option => t(option.value)}
                            getOptionValue={option => option.id}
                            options={INSTALL_TYPE_LIST}/>
                    </>
                </div>

                {/* Quick Installation & Logout */}
                <div className='text-center my-4'>
                    <a href="https://www.ubicquia.com/sites/default/files/Ubicquia_UbiGrid_DTM_Installation_Manual.pdf"
                    className="btn btn-secondary text-primary fw-bold standard-btn ">{t('view_installation_title')}</a>
                </div>
                <br />

            </div>
        </div>
        {showChangePassPrompt && (
                <UserPrompt data={
                {
                    questionTitle: t('change_password_title'), 
                    questionText: <div>
                        <p>{t('enter_new_pass')}</p>
                        <form>
                            <div className="mb-3 mt-4 w-100">
                                <input type="password" className="ubi-text-input" value={password} onChange={e => setPassword(e.target.value)} 
                                    id="change-pass-1" autoComplete="new-password" placeholder="New password"/>
                            </div>
                            <div className="mb-5 mt-4 w-100">
                                <input type="password" className="ubi-text-input" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)}
                                id="change-pass-2" autoComplete="new-password" placeholder="Retype new password"/>
                            </div>
                        </form>
                    </div>, 
                    disagreeText: t('cancel'),
                    agreeText : t("submit"),
                    onAgree: () => handleChangePasswordRes(true),
                    onDisagree: () => handleChangePasswordRes(false)
                }
        } />)} 
        {changePasswordError && <BottomAlert text={t('error_changing_pass')} icon={false}/>}
    </>
  )
}

export default Account

const INSTALL_TYPE_LIST = [
    {
        id : 0, 
        value : 'field_install'
    },
    {
        id : 1,
        value : 'store_room'
    }, 
]