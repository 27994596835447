// import dependencies 
import React , { useEffect, useState} from 'react'
import * as cn from '../redux/constants'
import {  useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

// import assets 
import ubipin from '../img/ubipin.svg'
import profile from '../img/profile.svg'
import ubigridLogo from '../img/UbiGrid_DTM_Logo.svg'
import logoutBtn from '../img/logout-btn.svg'

// import components 
import UserPrompt from './UserPrompt';

// import API redux actions 
import { logout } from '../redux/actions';
import { clearInstallLocalStorage } from '../utils';

const Header = () => {
    // hooks 
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t, i18n} = useTranslation()

    // component state
    const [pageTitle, setPageTitle] = useState('')
    const [error, setError] = useState(null)
    const [errorText, setErrorText] = useState('')
    const [showLogo, setShowLogo] = useState(true)
    const [showLogout, setShowLogout] = useState(false)
    const [showAccountBtn, setShowAccountBtn] = useState(false)
    const [showHeader, setShowHeader] = useState(true)
    const [showLogoutPrompt, setShowLogoutPrompt] = useState(false)
    const [showExitInstallPrompt, setShowExitInstallPrompt] = useState(false)
    const [showSessionExpiredPrompt, setShowSessionExpiredPrompt] = useState(false)
    

    // global state 
    const submitInstallationError = useSelector(state => state.submitInstallation?.error)
    const checkConnectionError =  useSelector(state => state.checkConnection?.error)
    const userLogin = useSelector(state => state.userLogin)
    const installationTypeSelected = useSelector(state => state.installationTypeSelected?.value)
    const { user } = userLogin

    
    // determine what kind of header to display depending on the url 
    const determineHeader = () => {
        switch (location.pathname) {
            case '/login':
                setShowHeader(true)
                setPageTitle('sign_in')
                setShowLogout(false)
                setShowLogo(false)
                setShowAccountBtn(false)
                break;
            case '/forgot-password':
                setShowHeader(true)
                setPageTitle('forgot_password_page_title')
                setShowLogout(false)
                setShowLogo(true)
                break;
            case '/choose-customer':
                setShowHeader(true)
                setPageTitle('choose_customer')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install-type':
                setShowHeader(true)
                setPageTitle('install_type')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/scan-unit':
                setShowHeader(true)
                setPageTitle('scan_unit_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/input-kva':
                setShowHeader(true)
                setPageTitle('input_kva_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/select-mns':
                setShowHeader(true)
                setPageTitle('select_mns_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/input-mns':
                setShowHeader(true)
                setPageTitle('select_mns_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/take-picture/transformer-plate':
                setShowHeader(true)
                setPageTitle('transformer_plate')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/take-picture/unit-photo':
                setShowHeader(true)
                setPageTitle('unit_photo_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/take-picture/primaries-photo':
                setShowHeader(true)
                setPageTitle('primaries_photo_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/take-picture/secondaries-photo':
                setShowHeader(true)
                setPageTitle('secondaries_photo_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/take-picture/prv-photo':
                setShowHeader(true)
                setPageTitle('prv_photo_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/take-picture/pairing-photo':
                setShowHeader(true)
                if(installationTypeSelected === 'field_install') {
                    setPageTitle('install_photo_title')
                } else{
                    setPageTitle('pairing_photo_title')
                }
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/paired-unit':
                setShowHeader(true)
                setPageTitle('paired_unit_page_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/installed-photo':
                setShowHeader(true)
                setPageTitle('install_photo_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/plate-manual-input':
                setShowHeader(true)
                setPageTitle('manual_input')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/unable-to-scan':
                setShowHeader(true)
                setPageTitle('unable_to_scan')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/connection-verification':
                setShowHeader(true)
                setPageTitle('connection_verification_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/installed-unit':
                setShowHeader(true)
                if(installationTypeSelected === 'field_install') {
                    setPageTitle('installed_unit_title')
                } else{
                    setPageTitle('paired_unit_title')
                }
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/install/tln':
                setShowHeader(true)
                setPageTitle('tln_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/account':
                setShowHeader(true)
                setPageTitle('account_page_title')
                setShowLogout(true)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/logs/view':
                setShowHeader(true)
                setPageTitle('logs_page_title')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(false)
                break;
            case '/map':
                setShowHeader(false)
                break;
            case '/' : 
                setShowHeader(true)
                setPageTitle('home')
                setShowLogout(false)
                setShowLogo(true)
                setShowAccountBtn(true)
                break;
            default:
                if(location.pathname.startsWith('/logs/node')){
                    setShowHeader(false)
                    return
                }
                setShowHeader(true)
                setPageTitle('Ubigrid DTM+')
                setShowLogout(false)
                setShowLogo(true)
                break;
        }
    }

    
    // Listen for location change
    useEffect(() => {
        determineHeader()
    }, [location])

    // Show logout prompt
    const handleLogout = () => {
        setShowLogoutPrompt(true)
    }

    // if we are on install page, remove install state from localstorage 
    const handleAccountClick = () => navigate('/account')

    // if on install page and not qr scanner page , ask before going home
    const handleLogoClick = () => {
        // clear the node info state when exiting installation 
        if(location.pathname.startsWith('/install/')){
            dispatch({type : cn.NODE_RESET})
        }

        if(location.pathname.startsWith('/install/') && location.pathname !== ('/install/scan-unit')){
            setShowExitInstallPrompt(true)
        } else{
            navigate('/')
        }

    }

    // clear states for install components
    const handleExitInstall = () => {
        dispatch({type : cn.CHECK_CONNECTION_RESET})
        dispatch({type : cn.NODE_RESET})
        clearInstallLocalStorage()
        navigate('/')
        setShowExitInstallPrompt(false)
    }

    // on user logout
    const signOut = () => {
        dispatch(logout())
        setShowLogoutPrompt(false)
    }
    
    // Handle Session Expired Response
    const onSessionExpired = () => {
        setShowSessionExpiredPrompt(false)
        dispatch(logout())
        navigate('/login')
    }

    // Check for token expired (Session Expired)
    useEffect(() => {
        const now = Math.floor(Date.now() / 1000)
        if (user && user.exp < now && !showSessionExpiredPrompt ) {
            setShowSessionExpiredPrompt(x => true)
        } else {
            setShowSessionExpiredPrompt(x => false)
        }
    // eslint-disable-next-line
    }, [userLogin])

    // Check Submit install error 
    useEffect(() => {
        if (submitInstallationError){
            setErrorText(<span>{t(submitInstallationError)} <br/><br/></span>)
            setError(true)
        }
    }, [submitInstallationError])

    useEffect(() => {
        if (checkConnectionError){
            setErrorText(<span>{t(checkConnectionError)} <br/><br/></span>)
            setError(true)
        }
    }, [checkConnectionError])

    // Handle Error Prompt
    const onHandleErrorPrompt = () => {
        setError(false)
        setErrorText('')
        if(submitInstallationError === 'offline_submit_install_msg'){
            navigate('/')
        }
    }

    return (
        <>
        {/* User Prompt to show exit install prompt */}
        {showExitInstallPrompt &&
            <UserPrompt data={
                {
                    show: true,
                    questionTitle : t('exit_install_title'),
                    questionText : t('exit_install_text'),
                    onAgree : handleExitInstall,
                    onDisagree : () => setShowExitInstallPrompt(false),
                    agreeText : t('yes'),
                    disagreeText : t('no')
                }
            } />
        }

        {/* User Prompt to show log out prompt */}
        {showLogoutPrompt &&
            <UserPrompt data={
                {
                    show: true,
                    questionTitle : t('sign_out'),
                    questionText : t('sing_out_prompt'),
                    onAgree : signOut,
                    onDisagree : () => setShowLogoutPrompt(false),
                    agreeText : t('yes'),
                    disagreeText : t('cancel')
                }
            } />
        }

        {/* Special prompt to show if login token expired (12h) */}
        {showSessionExpiredPrompt &&
            <UserPrompt data={
            {
                questionTitle: t('session_expired_title'), 
                questionText: t('session_expired_text'), 
                agreeText: t('ok'),
                onAgree: () => onSessionExpired(true),
            }
            } />
        }

        {/* Special prompt for any error that may occur */}
        {error &&
            <UserPrompt data={
            {
                questionTitle: t('error'), questionText: errorText, agreeText: t('ok'),
                onAgree: () => onHandleErrorPrompt(true),
            }
            } />
        }

        {showHeader && <div className='bg-primary text-light' id="header">
            <div className='py-2 px-3 position-relative'>
                <div className="row">
                    <div className="col-2">
                        {/* disable if on choose customer or intstall type */}
                        { showLogo && <img className={`ubipin-header ${ (location.pathname === '/choose-customer' || location.pathname === '/install-type') ? 'pe-none' : '' }`} role='button' 
                            onClick={handleLogoClick} alt="ubi pin header" src={ubipin}/>
                        }
                    </div>
                    {location.pathname === '/login' ? (
                        <div className="img-container company-logo mt-3">
                            <img className="d-block mx-auto home-pin" src={ubipin} alt="ubi pin home" />

                            <div>
                                <img className="d-block my-2 mx-auto ubigrid-logo" src={ubigridLogo} alt="ubi pin logo"
                                />
                                <p className='text-center mb-0 mx-auto'>{t('welcome_msg')}</p>
                            </div>
                        </div>
                    ) : (
                        <div className={`col-8 d-flex justify-content-center align-items-center 
                        ${i18n.language === "es" && (pageTitle === 'input_kva_title' || pageTitle === 'transformer_plate') ? 'language-title-fit' : '' }`}>
                            <p className={`text-center fs-3 m-0 fw-bold text-nowrap ps-3`}>{t(pageTitle)}</p>
                        </div>
                    )}                    
                    <div className="col-2 d-flex justify-content-center align-items-center">
                        {showLogout && <button onClick={handleLogout} className='btn'><img src={logoutBtn} alt="ubi logout btn"></img></button>}
                        {showAccountBtn && <img role='button' onClick={handleAccountClick} src={profile} alt="ubi profile btn"/>}
                    </div>
                </div> 
            </div>
        </div>}
        
        </>
    )
}

export default Header
